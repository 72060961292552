// 金額數字千分位 comma
// ex: -1231.02 -> -1,231.02

/**
 * 將數字格式化為帶有千位分隔符的字符串
 *
 * @param {number} num - 要格式化的數字
 * @returns {string} - 格式化後的字串
 */
export const numberThousandsComma = (num) => {
  if (isFinite(num)) {
    const isNegativeNum = num < 0
    const numString = String(Math.abs(num)) // '1231.02'
    const integerNumString = numString.split('.')?.[0] // '1231'
    const decimalNumString = numString.split('.')?.[1] // '02'
    let result = ''

    for (let i = integerNumString?.length - 1, j = 0; i >= 0; i--, j++) {
      if (j !== 0 && j % 3 === 0) {
        result = ',' + result
      }
      result = integerNumString[i] + result
    }

    if (isNegativeNum) result = `-${result}` // 負數
    if (decimalNumString) result += `.${decimalNumString}` // 小數點

    return result
  } else {
    return num
  }
}
