<template>
  <div
    class="bg d-flex justify-content-center align-items-center overflow-hidden"
  >
    <!-- loading overlay 設定 no-wrap 滿版，不用包住整個內容 -->
    <!-- 父層需是 relative ， z-index 預設 10 -->
    <b-overlay :show="isLoadingData" spinner-variant="secondary" no-wrap />

    <div class="wrap">
      <div class="contentRow d-flex justify-content-between">
        <div class="contentColumn">
          <!-- block 1 -->
          <div class="block logo">
            <img
              src="../assets/acme-logo-lg.png"
              class="logoImg"
              alt="ACME Logo Image"
            />
            <div class="logoTitle">進金生能源服務股份有限公司</div>
          </div>

          <!-- block 2 -->
          <div
            class="block totalPower shineLine d-flex flex-column justify-content-center"
          >
            <div class="d-flex justify-content-between">
              <div>
                <img
                  class="flashImg"
                  src="../assets/flash.png"
                  alt="Flash Icon Image"
                />今日總發電量
              </div>
              <div>
                <span class="spacing-5 num">{{ data2.todayPowerSum }}</span>
                &ensp;度
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <div>
                <img
                  class="flashImg"
                  src="../assets/flash.png"
                  alt="Flash Icon Image"
                />本月總發電量
              </div>
              <div>
                <span class="spacing-5 num">{{ data2.monthPowerSum }}</span>
                &ensp;度
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <div>
                <img
                  class="flashImg"
                  src="../assets/flash.png"
                  alt="Flash Icon Image"
                />今年總發電量
              </div>
              <div>
                <span class="spacing-5 num">{{ data2.yearPowerSum }}</span>
                &ensp;度
              </div>
            </div>
          </div>

          <!-- block 3 -->
          <div class="block totalSite shineLine d-flex flex-column">
            <div class="blockTitle d-flex align-items-center">
              <img
                src="../assets/acme-icon.png"
                alt="ACME Icon Image"
                height="100%"
              />
              <div class="iconText">進金生維護運轉中</div>
            </div>

            <div
              class="blockContent d-flex flex-column justify-content-between"
            >
              <div class="d-flex justify-content-between">
                <div class="title"></div>
                <div class="siteNum">案場數</div>
                <div class="deviceTitle">&ensp;&ensp;&ensp;裝置量</div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="title">北區</div>
                <div class="num siteNum">
                  {{ data3.northSiteTotal }}
                </div>
                <div class="num deviceNum">
                  {{ data3.northCapacityTotal }}&ensp;MW
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="title">中區</div>
                <div class="num siteNum">
                  {{ data3.midSiteTotal }}
                </div>
                <div class="num deviceNum">
                  {{ data3.midCapacityTotal }}&ensp;MW
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="title">南區</div>
                <div class="num siteNum">
                  {{ data3.southSiteTotal }}
                </div>
                <div class="num deviceNum">
                  {{ data3.southCapacityTotal }}&ensp;MW
                </div>
              </div>

              <div class="my-1 divider"></div>

              <div class="d-flex justify-content-between">
                <div class="title">總計</div>
                <div class="num siteNum">
                  {{ data3.siteTotal }}
                </div>
                <div class="num deviceNum">
                  {{ data3.capacityTotal }}&ensp;MW
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="contentColumn">
          <!-- block 4 -->
          <transition name="cardLooping">
            <div
              v-show="isData4Show"
              class="block info shineLine d-flex justify-content-center align-items-center"
              :class="{
                'en-text': data4?.factoryName?.includes('TotalEnergies'),
              }"
            >
              <div>
                <div>
                  案場名稱：&ensp;<span class="font-weight-bold">{{
                    data4?.factoryName ?? "--"
                  }}</span>
                </div>
                <div>
                  案場地點：&ensp;<span class="font-weight-bold">{{
                    data4?.address ?? "--"
                  }}</span>
                </div>
                <div>
                  裝置容量：&ensp;<span class="spacing-5 font-weight-bold"
                    >{{
                      data4?.cap
                        ? data4.cap.split("，共")[1]?.split("kWp")[0]
                          ? data4.cap.split("，共")[1].split("kWp")[0].trim()
                          : extractNumber(data4.cap)
                        : "--"
                    }}&ensp;kWp</span
                  >
                </div>
                <div>
                  併網時間：&ensp;<span class="spacing-5 font-weight-bold">{{
                    data4?.conbinedDate ?? "--"
                  }}</span>
                </div>
              </div>
            </div>
          </transition>

          <!-- block 5 -->
          <transition name="cardLooping">
            <div v-show="isData5Show" class="block photo">
              <b-carousel
                id="carousel-fade"
                fade
                :interval="imageTimeInterval"
                v-model="slideIndex"
              >
                <b-carousel-slide
                  v-for="(i, index) in showImageList"
                  :key="index"
                >
                  <template #img>
                    <div class="photo">
                      <img class="siteImg" :src="i" alt="Site Image" />
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </transition>

          <!-- block 6 -->
          <transition name="cardLooping">
            <div
              v-show="isData6Show"
              ref="refTodayPower"
              class="block powerWrap shineLine d-flex flex-column justify-content-between"
            >
              <div class="desc d-flex font-weight-bold">
                <div>
                  今日發電量：
                  <span class="spacing-5">{{ data6.dayKwhSum }}</span>
                  度&emsp;&emsp;
                </div>
                <div>
                  售電金額： <span>{{ data6.daySellPriceSum }}</span> 元
                </div>
              </div>
              <div class="powerChart">
                <highcharts :options="data6.chart"></highcharts>
              </div>
            </div>
          </transition>
        </div>

        <div class="contentColumn">
          <!-- block 7 -->
          <transition name="cardLooping">
            <div
              v-show="isData7Show"
              class="weather block shineLine d-flex flex-column justify-content-between"
            >
              <!-- weather info -->
              <div
                class="d-flex justify-content-between align-items-center my-auto"
              >
                <div class="d-flex flex-column justify-content-between">
                  <div class="date">{{ today }}</div>
                  <div class="county">
                    {{ data7.address }}
                  </div>
                  <div class="desc">
                    {{ data7.desc }}
                  </div>
                </div>

                <div class="d-flex align-items-center position-relative">
                  <div class="d-flex flex-column">
                    <div class="temp ml-auto">{{ data7.tempRangeDesc }}</div>
                    <div class="d-flex ml-auto">
                      <!-- umbrella -->
                      <div
                        v-if="data7.rain"
                        class="d-flex align-items-center ml-auto"
                      >
                        <img
                          class="rainIcon"
                          src="../assets/umbrella.png"
                          alt="Umbrella Image"
                        />
                        <span class="rainText"> {{ data7.rain }} mm </span>
                      </div>

                      <!-- cloud -->
                      <div
                        v-if="data7.clouds"
                        class="d-flex align-items-center ml-3"
                      >
                        <img
                          class="cloudIcon"
                          src="../assets/cloud.png"
                          alt="Umbrella Image"
                        />
                        <span class="rainText"> {{ data7.clouds }} % </span>
                      </div>
                    </div>
                  </div>

                  <div class="weatherImgWrap">
                    <img class="weatherImg" :src="data7.imgUrl" />
                  </div>
                </div>
              </div>

              <!-- week weather -->
              <div>
                <div class="weekWeatherTitle d-flex align-items-center">
                  <div class="text-nowrap mr-3">一週天氣預報</div>
                  <div class="divider w-100"></div>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <div
                    v-for="weekObj in data7.weekDayList"
                    :key="weekObj.weekday"
                    class="d-flex flex-column align-items-center"
                  >
                    <div class="weekDayText">{{ weekObj.weekday }}</div>
                    <div
                      class="weekWeatherImg"
                      :style="{ backgroundImage: `url( ${weekObj.imgUrl} )` }"
                    />
                    <!-- <img class="weekWeatherImg" :src="weekObj.imgUrl" alt="Week Weather Image" /> -->
                    <div class="weekTempText">{{ weekObj.tempRange }}</div>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- block 8 -->
          <transition name="cardLooping">
            <div
              v-show="isData8Show"
              class="block powerWrap shineLine d-flex flex-column justify-content-between"
            >
              <div class="desc d-flex font-weight-bold">
                <div>
                  本月發電量：
                  <span class="spacing-5">{{ data8.monKwhSum }}</span>
                  度&emsp;&emsp;
                </div>
                <div>
                  售電金額： <span>{{ data8.monSellPriceSum }}</span> 元
                </div>
              </div>
              <div class="powerChart">
                <highcharts :options="data8.chart"></highcharts>
              </div>
            </div>
          </transition>

          <!-- block 9 -->
          <transition name="cardLooping">
            <div
              v-show="isData9Show"
              class="block powerWrap shineLine d-flex flex-column justify-content-between"
            >
              <div class="desc d-flex font-weight-bold">
                <div>
                  今年發電量：
                  <span class="spacing-5">{{ data9.yearKwhSum }}</span>
                  度&emsp;&emsp;
                </div>
                <div>
                  售電金額： <span>{{ data9.yearSellPriceSum }}</span> 元
                </div>
              </div>
              <div class="powerChart">
                <highcharts :options="data9.chart"></highcharts>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <transition name="timeFlipping">
        <div v-show="isUpdateTimeShow" class="footer">
          更新於 {{ updateTime }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { BOverlay, BCarousel, BCarouselSlide } from 'bootstrap-vue'
import i18n from '../lang/lang'
import { get14fSelectedApi, get14fDataApi, getLinkBlobApi } from '../api'
import { numberThousandsComma } from '../utils/numberThousandsComma'
import defaultImg from '../assets/login_bg.jpg'
import clonedeep from 'lodash.clonedeep'

const chartSetting = {
  chart: {
    height: 170,
    backgroundColor: 'transparent'
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  colors: ['#55abe6', '#3b88c9'],
  tooltip: {
    shared: true,
    borderColor: '#3b88c9',
    style: {
      letterSpacing: '1px'
    },
    distance: 20,
    followPointer: true
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      label: {
        enabled: false
      },
      borderColor: '#55abe6', // default #fffff
      marker: {
        enabled: false,
        radius: 4, // 圖例大小
        states: {
          hover: {
            radiusPlus: 1
          }
        }
      }
    }
  },
  yAxis: [
    {
      title: { text: '' } // 發電量 (kWh)
    },
    {
      title: { text: '' }, // 售電金額 (NTD)
      opposite: true,
      gridLineColor: '#87bae6'
    }
  ]
}

const dataChart = {
  ...chartSetting,
  xAxis: {
    categories: [], // 根據 api data 填入
    lineColor: '#87bae6'
  },
  series: [
    {
      name: '發電量',
      type: 'column',
      yAxis: 0,
      data: [], // 根據 api data 填入
      tooltip: {
        valueSuffix: ' 度'
      }
    },
    {
      name: '售電金額',
      type: 'spline',
      yAxis: 1,
      data: [], // 根據 api data 填入
      tooltip: {
        valueSuffix: ' 元'
      }
    }
  ]
}

export default {
  name: 'TvWallLooping',
  components: {
    BOverlay,
    BCarousel,
    BCarouselSlide
  },
  data () {
    return {
      today: DateTime.now().toFormat('MM/dd'),
      updateTime: DateTime.now().toFormat('y/MM/dd HH:mm:ss'),
      isLoadingData: true,
      isData4Show: true,
      isData5Show: true,
      isData6Show: true,
      isData7Show: true,
      isData8Show: true,
      isData9Show: true,
      isUpdateTimeShow: true,
      intervalDataId: null,
      dataTimeInterval: 60000,
      // 此時展示的 data index -> 取得 next index to call api 、 imageMap.get
      nowDataIndex: 0,
      factoryNameList: [],
      allData: {
        wholeData: {},
        singleData: {},
        weatherData: {}
      },
      showFactoryImageMap: new Map(), // key: fId, value: imageList
      preLoadAllData: { wholeData: {}, singleData: {}, weatherData: {} },
      showImageList: [defaultImg],
      imageTimeInterval: 60000,
      slideIndex: 0,
      data4: {},
      data7: {
        address: '',
        desc: '',
        tempRangeDesc: '',
        rain: 0,
        clouds: 0,
        imgUrl: '',
        weekDayList: [
          // { weekDay: '星期一', imgUrl: '', tempRange: '' }, ...
        ]
      },
      data6: {
        dayKwhSum: 0,
        daySellPriceSum: 0,
        chart: clonedeep(dataChart)
      },
      data8: {
        monKwhSum: 0,
        monSellPriceSum: 0,
        chart: clonedeep(dataChart)
      },
      data9: {
        yearKwhSum: 0,
        yearSellPriceSum: 0,
        chart: clonedeep(dataChart)
      }
    }
  },
  computed: {
    nextCallDataIndex () {
      let nextIdx = this.nowDataIndex + 1
      return nextIdx > this.factoryNameList?.length - 1 ? 0 : nextIdx
    },
    singleData () {
      return this.allData?.singleData
    },
    weatherDataCurrent () {
      return this.allData?.weatherData?.current
    },
    weatherDataDaily () {
      return this.allData?.weatherData?.daily
    },
    data2 () {
      const wholeData = this.allData?.wholeData

      const todayPowerSum = this.isNumber(wholeData?.daySumKw)
        ? numberThousandsComma(Math.round(wholeData?.daySumKw * 1000))
        : '--'
      const monthPowerSum = this.isNumber(wholeData?.monSumKw)
        ? numberThousandsComma(Math.round(wholeData?.monSumKw * 1000))
        : '--'
      const yearPowerSum = this.isNumber(wholeData?.yearSumKw)
        ? numberThousandsComma(Math.round(wholeData?.yearSumKw * 1000))
        : '--'
      return { todayPowerSum, monthPowerSum, yearPowerSum }
    },
    data3 () {
      const wholeData = this.allData?.wholeData

      const siteTotal = wholeData?.totalFactoriesCount ?? '--'
      const capacityTotal = this.isNumber(wholeData?.totalFactoriesCapMw)
        ? Math.round(wholeData?.totalFactoriesCapMw * 100) / 100
        : '--'

      const northSiteTotal = wholeData?.north?.count ?? '--'
      const northCapacityTotal = this.isNumber(wholeData?.north?.capMw)
        ? Math.round(wholeData?.north?.capMw * 100) / 100
        : '--'

      const midSiteTotal = wholeData?.mid?.count ?? '--'
      const midCapacityTotal = this.isNumber(wholeData?.mid?.capMw)
        ? Math.round(wholeData?.mid?.capMw * 100) / 100
        : '--'

      const southSiteTotal = wholeData?.south?.count ?? '--'
      const southCapacityTotal = this.isNumber(wholeData?.south?.capMw)
        ? Math.round(wholeData?.south?.capMw * 100) / 100
        : '--'

      return {
        siteTotal,
        capacityTotal,
        northSiteTotal,
        northCapacityTotal,
        midSiteTotal,
        midCapacityTotal,
        southSiteTotal,
        southCapacityTotal
      }
    }
  },
  methods: {
    numberThousandsComma (num) {
      return numberThousandsComma(num)
    },
    extractNumber (inputString) {
      if (inputString) {
        // 使用正則表達式匹配數字和小數點
        const match = inputString.match(/(\d+(\.\d+)?)\s*k?Wp/i)

        // 如果找到匹配，則提取數字部分，否則返回 inputString
        return match ? parseFloat(match[1]) : inputString
      }
      return inputString
    },
    resizeCharts () {
      // chart height
      this.$nextTick(() => {
        const refPowerBlockHeight =
          this.$refs.refTodayPower.getBoundingClientRect().height
        this.data6.chart.chart.height = refPowerBlockHeight * 0.7
        this.data8.chart.chart.height = refPowerBlockHeight * 0.7
        this.data9.chart.chart.height = refPowerBlockHeight * 0.7
      })
    },
    toggleSetInterval () {
      if (document.hidden) {
        // 頁面不可見
        window.clearInterval(this.intervalDataId)
        this.intervalDataId = null
      } else {
        // 頁面可見
        // setInterval -> looping data
        this.setIntervalDataId()
      }
    },
    setIntervalDataId () {
      // 設定時間間格 翻 card，翻 (isDataShow false) -> 更新資料(updateData) -> 翻完 (isDataShow true)
      // isDataShow false 轉場 -> 更新 data (preLoadData 載進 allData) -> isDataShow true (幾乎同時 set data 更新展示下個 data)
      this.intervalDataId = setInterval(async () => {
        this.isData4Show = false // transition leave

        await this.delay(0.2)
        this.isData5Show = false

        await this.delay(0.2)
        this.isData6Show = false

        await this.delay(0.2)
        this.isData7Show = false

        await this.delay(0.2)
        this.isData8Show = false

        await this.delay(0.2)
        this.isData9Show = false

        await this.delay(0.2)
        this.isUpdateTimeShow = false

        await this.updateData()
      }, this.dataTimeInterval)
    },
    delay (s) {
      return new Promise((resolve) => setTimeout(resolve, s * 1000))
    },
    isNumber (value) {
      return typeof value === 'number' && !isNaN(value)
    },
    changeNowDataIndex () {
      // 若超過 name list 數 則跳回 index 0
      let nextIdx = this.nowDataIndex + 1
      if (nextIdx > this.factoryNameList?.length - 1) {
        this.nowDataIndex = 0
      } else {
        this.nowDataIndex++
      }
    },
    setData5 () {
      // 顯示第一個案場輪播照片第一張 (顯示第 idx 0 照片)
      this.slideIndex = 0

      this.showImageList = this.showFactoryImageMap.get(this.singleData?.fId)
        ?.length
        ? this.showFactoryImageMap.get(this.singleData?.fId)
        : [defaultImg]

      // 一分鐘輪播內 有幾張照片 就播幾張: 60s/照片數
      this.imageTimeInterval =
        this.dataTimeInterval / this.showImageList.length
    },
    setData6 () {
      // 今日 發電量、售電金額
      this.data6.dayKwhSum = numberThousandsComma(
        Math.round(this.singleData?.dayKwhSum * 10) / 10
      )
      this.data6.daySellPriceSum = numberThousandsComma(
        Math.round(
          this.singleData?.dayKwh?.reduce((acc, cur) => acc + cur?.sellPrice, 0)
        )
      )

      // chart 時 list
      this.data6.chart.xAxis.categories = this.singleData?.dayKwh?.map(
        (obj) => obj.time
      )
      // chart 發電量
      this.data6.chart.series[0].data = this.singleData?.dayKwh?.map(
        (obj) => Math.round(obj.kWh * 10) / 10
      )
      // chart 售電金額
      this.data6.chart.series[1].data = this.singleData?.dayKwh?.map((obj) =>
        Math.round(obj?.sellPrice)
      )
    },
    setData8 () {
      // 本月 發電量、售電金額
      this.data8.monKwhSum = numberThousandsComma(
        Math.round(this.singleData?.monKwhSum * 10) / 10
      )
      this.data8.monSellPriceSum = numberThousandsComma(
        Math.round(
          this.singleData?.monKwh?.reduce((acc, cur) => acc + cur?.sellPrice, 0)
        )
      )

      // chart 日 list
      this.data8.chart.xAxis.categories = this.singleData?.monKwh?.map(
        (obj) =>
          String(DateTime.local().month).padStart(2, '0') +
          '/' +
          obj.time.split('-')[2]
      )
      // chart 發電量
      this.data8.chart.series[0].data = this.singleData?.monKwh?.map(
        (obj) => Math.round(obj.kWh * 10) / 10
      )
      // chart 售電金額
      this.data8.chart.series[1].data = this.singleData?.monKwh?.map((obj) =>
        Math.round(obj?.sellPrice)
      )
    },
    setData9 () {
      // 今年 發電量、售電金額
      this.data9.yearKwhSum = numberThousandsComma(
        Math.round(this.singleData?.yearKwhSum * 10) / 10
      )
      this.data9.yearSellPriceSum = numberThousandsComma(
        Math.round(
          this.singleData?.yearKwh?.reduce(
            (acc, cur) => acc + cur?.sellPrice,
            0
          )
        )
      )

      // chart 月 list
      this.data9.chart.xAxis.categories = this.singleData?.yearKwh?.map(
        (obj) => Number(obj.time.split('-')[1]) + '月'
      )
      // chart 發電量
      this.data9.chart.series[0].data = this.singleData?.yearKwh?.map(
        (obj) => Math.round(obj.kWh * 10) / 10
      )
      // chart 售電金額
      this.data9.chart.series[1].data = this.singleData?.yearKwh?.map((obj) =>
        Math.round(obj?.sellPrice)
      )
    },
    setData7 () {
      const imgIconNo = this.weatherDataCurrent?.weather?.[0]?.icon
      const hasWeatherDataDaily = !!this.weatherDataDaily?.length

      this.data7 = {
        address: this.singleData?.address?.split(' ')?.[0] ?? '--',
        desc: this.weatherDataCurrent?.weather?.[0]?.description ?? '--',
        tempRangeDesc: this.isNumber(this.weatherDataDaily?.[0]?.temp?.min)
          ? `${Math.round(
              this.weatherDataDaily?.[0]?.temp?.min
          )}° - ${Math.round(this.weatherDataDaily?.[0]?.temp?.max)}°`
          : '--',
        rain: this.weatherDataCurrent?.rain?.['3h'],
        clouds: this.weatherDataCurrent?.clouds?.all,
        imgUrl: imgIconNo ? require(`../assets/weather/${imgIconNo}.png`) : '',
        // 一周氣象資訊列表
        weekDayList: hasWeatherDataDaily
          ? this.weatherDataDaily.map((obj) => ({
            weekday: i18n.t(
              `weekday.${DateTime.fromSeconds(obj.dt).toFormat('cccc')}`
            ),
            imgUrl: require(`../assets/weather/${obj.weather[0].icon}.png`),
            tempRange: `${Math.round(obj.temp.min)}° - ${Math.round(
              obj.temp.max
            )}°`
          }))
          : []
      }
    },
    async updateData () {
      // preLoadData to allData
      this.allData = { ...this.preLoadAllData }
      this.changeNowDataIndex() // 更新即將展示的 data index

      // show & set data
      // (翻轉出現時 才更新 data，才不會跳 next data 才翻轉出現)
      this.isData4Show = true // transition enter
      this.data4 = this.singleData

      await this.delay(0.2)
      this.isData5Show = true
      this.setData5()

      await this.delay(0.2)
      this.isData6Show = true
      this.setData6()

      await this.delay(0.2)
      this.isData7Show = true
      this.setData7()

      await this.delay(0.2)
      this.isData8Show = true
      this.setData8()

      await this.delay(0.2)
      this.isData9Show = true
      this.setData9()

      await this.delay(0.2)
      this.isUpdateTimeShow = true
      this.updateTime = DateTime.now().toFormat('y/MM/dd HH:mm:ss') // 更新資料時間

      await this.getPreLoad14fData() // 預載 next data
    },
    async get14fSelectedFactoryNameList () {
      try {
        const res = await get14fSelectedApi()
        if (res.data.success) {
          this.factoryNameList = res.data.data
        }
      } catch (err) {
        this.$swal('案場列表載入異常，請重新整理或洽網站管理員')
        console.log('get14fSelectedFactoryNameList', err)
      }
    },

    async get14fData (factoryName) {
      try {
        const res = await get14fDataApi(factoryName)
        if (res?.data?.success) {
          this.allData = res.data.data
        }
      } catch (err) {
        this.$swal.fire({
          text: `${factoryName} 案場資訊載入異常，請通知網站管理員`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000
        })

        this.errorStorage(factoryName, err)
      }
    },

    async getFactoryImageLinkList (id) {
      try {
        const res = await getLinkBlobApi(id)
        if (res?.data?.success) {
          this.showFactoryImageMap.set(id, res.data.data)
        }
      } catch (err) {
        this.$swal('案場圖片載入異常，請重新整理或洽網站管理員')
        console.log('getFactoryImageLinkList', err)
      }
    },

    async getPreLoad14fData () {
      const nextFactoryName = this.factoryNameList[this.nextCallDataIndex]
      try {
        // 取得 14fData
        const res = await get14fDataApi(nextFactoryName)
        if (res?.data?.success) {
          this.preLoadAllData = res.data.data
        } else {
          this.preLoadAllData = {
            wholeData: {},
            singleData: {},
            weatherData: {}
          }
        }

        // 最新 preLoadAllData fid 取得 案場輪播照片清單
        // check showFactoryImageMap 是否已有下個要 call 的案場的照片
        // 只有在 get14fDataApi 成功時才取得 LinkBlobApi
        if (
          !this.showFactoryImageMap.get(this.preLoadAllData.singleData.fId) &&
          res?.data?.success &&
          this.preLoadAllData.singleData.fId
        ) {
          await this.getFactoryImageLinkList(
            this.preLoadAllData.singleData.fId
          )
        }
      } catch (err) {
        this.preLoadAllData = {
          wholeData: {},
          singleData: {},
          weatherData: {}
        }

        this.$swal.fire({
          text: `${nextFactoryName} 案場資訊預載異常，請通知網站管理員`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 3000
        })

        this.errorStorage(nextFactoryName, err)
      }
    },

    errorStorage (factoryName, errorMsg) {
      const time = DateTime.now().toFormat('y/MM/dd HH:mm:ss')
      // error 記錄 localStorage
      const hasTvWallErrorObj = JSON.parse(localStorage.getItem('TvWallError'))
      if (hasTvWallErrorObj) {
        const setErrorMsg = {
          error: hasTvWallErrorObj.error.concat(
            `${factoryName}__${time}__${errorMsg}`
          )
        }
        localStorage.setItem('TvWallError', JSON.stringify(setErrorMsg))
      } else {
        const setErrorMsg = { error: [`${factoryName}__${time}__${errorMsg}`] }
        localStorage.setItem('TvWallError', JSON.stringify(setErrorMsg))
      }
    }
  },
  async created () {
    window.addEventListener('resize', this.resizeCharts)
    window.addEventListener('visibilitychange', this.toggleSetInterval)

    // get data
    // 1. 取得可展示案場名稱列表
    await this.get14fSelectedFactoryNameList()
    // 2. 取案場列表第一個 dada
    await this.get14fData(this.factoryNameList[this.nowDataIndex])
    // 3. 最新 allData data id 取得 案場輪播照片清單
    await this.getFactoryImageLinkList(this.allData.singleData.fId)

    // set data
    this.data4 = this.singleData
    this.setData5()
    this.setData6()
    this.setData7()
    this.setData8()
    this.setData9()

    // show data
    this.isLoadingData = false

    // setInterval -> looping data
    this.setIntervalDataId()

    // 預載 next data
    await this.getPreLoad14fData()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeCharts)
    window.removeEventListener('visibilitychange', this.toggleSetInterval)
    window.clearInterval(this.intervalDataId)
    this.intervalDataId = null
    localStorage.removeItem('TvWallError')
  }
}
</script>

<style lang="scss" scoped>
// 共用
$color-main: #3b88c9;

.spacing-5 {
  letter-spacing: 0.5px;
}

// cover css
#app .sidebar-view-wrapper .router-view {
  font-family: system, -apple-system, BlinkMacSystemFont, "PingFang TC", Lato,
    "Noto Sans TC", Montserrat, Roboto, "Helvetica Neue", Arial, sans-serif;
  height: 100vh;
  padding: 0;
}

.bg {
  background-image: url("../assets/tvwalllooping_bg.jpg");
  background-size: cover;
  background-position-y: 80%;
}

.wrap {
  width: 93.5vw;
  height: 97vh;
}

.contentRow {
  height: 93vh;
}

.footer {
  height: calc(97vh - 93vh);

  padding-top: 0.2vw;

  font-size: 1.4vw;
  text-align: right;
  color: rgba(256, 256, 256, 0.85);
}

.contentColumn {
  // height: 92vh;
  width: calc((93.5vw - 1.2vw * 2) * 0.333);
  &:nth-child(2) {
    margin: 0 1.2vw; // margin 皆用 vw 才會計算後一致
  }
}

.block {
  background-color: rgba(256, 256, 256, 0.8);
  margin-top: 1.2vw; // margin 皆用 vw 才會計算後一致
  border-radius: 2px;
  box-shadow: rgba(35, 120, 158, 0.3) 0px 8px 10px 5px;
}

.shineLine {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    background-color: rgba(256, 256, 256, 0.5);
    height: 3%;
    width: 100%;
    transform: rotate(-65deg);
    box-shadow: 0 0 8px 8px rgba(256, 256, 256, 0.4);

    animation-name: shineLineAnimation;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes shineLineAnimation {
  from {
    left: -100vw;
  }
  to {
    left: 300vw;
  }
}

// column 1 ------------

// block 1
.logo {
  height: calc((93vh - 1.2vw * 3) * 0.355);
  padding: 2vw;
  // remove parent style (block)
  background-color: transparent;
  box-shadow: none;

  .logoImg {
    height: 80%;
    width: 100%;
    object-fit: contain;
    object-position: left;
  }
  .logoTitle {
    margin-top: 2%;

    font-weight: 500;
    font-size: 2vw;
    color: #555;
    text-align: center;
    letter-spacing: 0.02rem;
  }
}

// block 2
.totalPower {
  height: calc((93vh - 1.2vw * 3) * 0.265);

  padding: 1.2vw 2vw;

  font-size: 1.6vw;
  line-height: 2.8vw;
  color: #555;

  position: relative;
  overflow: hidden;
  z-index: 8;

  .num {
    font-weight: 700;
    font-size: 1.7vw;
  }

  .flashImg {
    width: 1.5vw;
    opacity: 0.8;

    animation-name: flashImgAnimation;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }
}
@keyframes flashImgAnimation {
  0% {
    transform: translate(-6px, -2px) perspective(1000px) rotateY(0deg)
      rotateZ(5deg);
  }
  45% {
    transform: translate(-6px, -2px) perspective(1000px) rotateY(0deg)
      rotateZ(5deg);
  }
  55% {
    transform: translate(-6px, -2px) perspective(1000px) rotateY(360deg)
      rotateZ(-5deg);
  }
  100% {
    transform: translate(-6px, -2px) perspective(1000px) rotateY(360deg)
      rotateZ(-5deg);
  }
}

// block 3
.totalSite {
  height: calc((93vh - 1.2vw * 3) * 0.38);

  padding: 1.2vw 4vw;

  position: relative;
  overflow: hidden;

  .blockTitle {
    height: 25%;
  }

  .iconText {
    margin-left: 1.3vw;

    font-size: 1.7vw;
    font-weight: 700;
    letter-spacing: 0.1rem;
    color: $color-main;
  }

  .blockContent {
    height: 75%;
    padding-top: 0.6vw;

    font-size: 1.3vw;
    color: #555;

    .title {
      width: 4.5vw;
    }

    .divider {
      border-top: solid 2px lighten(#555, 50%);
    }

    .num {
      // font-size: 1.4vw;
      font-weight: 900;
      color: $color-main;
    }

    .siteNum {
      width: 6vw;
      text-align: center;
    }

    .deviceTitle {
      width: 8.5vw;
      text-align: center;
    }

    .deviceNum {
      width: 8.5vw;
      text-align: right;
    }
  }
}

// column 2 ------------

// block 4
.info {
  height: calc((93vh - 26vh - 1.2vw * 3) * 0.42);

  font-size: 1.6vw;
  line-height: 2.8vw;
  color: #555;

  position: relative;
  overflow: hidden;

  &.en-text {
    font-size: 1.3vw;
  }
}

// block 5
.photo {
  height: calc((93vh - 26vh - 1.2vw * 3) * 0.58);

  .siteImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// block 6、8、9
.powerWrap {
  height: 26vh;

  padding: 1vw 1.4vw;

  position: relative;
  overflow: hidden;

  .desc {
    font-size: 0.95vw;
    color: $color-main;
  }

  .powerChart {
    margin: 0 -0.5vw -0.5vw -0.5vw;
  }
}

// column 3 ------------

// block 7
.weather {
  height: calc(93vh - 26vh * 2 - 1.2vw * 3);

  padding: 0vw 1.4vw 1vw 1.4vw;

  position: relative;
  overflow: hidden;

  .date {
    font-size: 1.2vw;
    color: #555;
  }
  .county {
    font-size: 1.6vw;
    font-weight: 500;
    color: #444;
  }
  .desc {
    font-size: 1.16vw;
    color: #555;
  }

  .temp {
    font-weight: 500;
    font-size: 3.2vw;
    line-height: 4vw;
    color: $color-main;
  }

  .rainIcon {
    height: 1.6vw;
  }

  .cloudIcon {
    width: 1.8vw;
  }

  .rainText {
    font-size: 1.4vw;
    letter-spacing: 0.05rem;
    color: $color-main;
    margin-left: 0.5vw;
  }

  .weatherImgWrap {
    position: relative;
    width: 8vw;
    height: 8vw;
    margin-left: 0.3vw;
    margin-right: -0.8vw;
  }

  .weatherImg {
    position: absolute;
    width: 100%;
    animation-name: weatherImgAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  // week weather

  .weekWeatherTitle {
    color: #666666dc;
    font-size: 0.75vw;
    margin-bottom: 0.5vw;
    letter-spacing: 1px;

    .divider {
      border-top: solid 1px #bbb;
    }
  }
  .weekDayText {
    font-size: 0.95vw;
    color: $color-main;
  }
  .weekTempText {
    font-size: 0.83vw;
    color: #666;
  }

  .weekWeatherImg {
    height: 3vw;
    width: 3vw;
    background-size: 90%;
    background-color: #5fc7fa;
    // filter: drop-shadow(3px 3px 2px rgba(80, 78, 78, 0.356));
    filter: brightness(105%);
    border-radius: 50%;
    background-position: center;
    margin: 0.4vw 0;
  }
}

@keyframes weatherImgAnimation {
  from {
    top: 5px;
  }
  to {
    top: -5px;
  }
}

// data transition
//  進入過程
.cardLooping-enter-active {
  transition: all 0.3s ease-out;
}
.timeFlipping-enter-active {
  transition: all 0.3s ease-out;
}

// 離開過程
.cardLooping-leave-active {
  // 這裡的 秒數 要與 call api await delay() '費時加總'一致
  transition: all 1.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.timeFlipping-leave-active {
  // isDataShow = false 時 leave
  // 這裡的 秒數 要與 call api await delay() 一致，false 起算至 true 的時間 (await 0.2*6)
  // 否則資料呈現與動畫效果不同步
  transition: all 1.2s cubic-bezier(1, 0.5, 0.8, 1);
}

// cardLooping 進入
// cardLooping 離開
.cardLooping-enter-from,
.cardLooping-leave-to {
  transform: perspective(1000px) rotateX(-90deg);
  opacity: 0;
}

// timeFlipping 進入
// timeFlipping 離開
.timeFlipping-enter-from,
.timeFlipping-leave-to {
  transform: rotateX(-90deg);
  opacity: 0;
}
</style>
